import * as React from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import './src/assets/_sass/global.scss';
import 'lightbox-react/style.css';
import 'react-datepicker/dist/react-datepicker.css';

// JSON-LD
export const jsonLd = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  name: 'ロイヤルパークホテルズ',
  alternateName: 'Royal Park Hotels',
  url: 'https://www.royalparkhotels.co.jp/',
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;700&family=Libre+Baskerville:wght@400;700&family=Noto+Sans+JP:wght@500&family=Noto+Serif+JP:wght@200;400;500;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Antic+Didone&display=swap"
          rel="stylesheet"
        />
        <script>{`
          (function(d) {
            var config = {
              kitId: 'qiy0fad',
              scriptTimeout: 3000,
              async: true
            },
            h=d.documentElement,t=setTimeout(function(){h.className = h.className.replace(/\bwf-loading\b/g, "") + " wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a = this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){ }};s.parentNode.insertBefore(tk,s)
  })(document);
        `}</script>
        <link
          rel="icon"
          href={withPrefix('/assets/images/common/favicon.png?v=1')}
          type="image/png"
        />
        <link
          rel="apple-touch-icon"
          sizes="512x512"
          href={withPrefix('/assets/images/common/favicon.png.png?v=1')}
        ></link>
        <link
          rel="apple-touch-icon"
          sizes="192x192"
          href={withPrefix('/assets/images/common/favicon.png?v=1')}
        ></link>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={withPrefix('/assets/images/common/favicon.png?v=1')}
        ></link>
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>
      {element}
    </>
  );
};

export const onRouteUpdate = ({ location }) => {
  /**
   * アンカー付き遷移でスライダーがあるときの位置のズレを調整
   * （iPhoneで現象あり）
   */
  setTimeout(function () {
    if (location.hash) {
      const targetElement = document.getElementById(
        location.hash.replace('#', '')
      );

      const offset = window.pageYOffset;
      const rect = targetElement
        ? targetElement.getBoundingClientRect().top
        : -offset;

      //htmlタグのcss情報を取得。scrool-padding-top必須
      const html_element = document.getElementsByTagName('html');
      const scrool_padding_top = getComputedStyle(
        html_element[0]
      ).scrollPaddingTop;
      const gap = parseInt(scrool_padding_top);

      const target = rect + offset - gap;
      window.scrollTo({
        top: target,
      });
    }
  }, 500);
};
